<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="container-fluid">
    <b-row>
      <b-col class="col-md-6 col-lg-4 col-xl-3">
        <!-- create new & pdf button -->
        <b-button-group class="pt-4 pb-2">
          <b-button
            class="btn-nc btn-green"
            tabindex="0"
            v-on:click="createActivity()"
            v-on:keyup.enter="createActivity()"
          >
            <i class="nc-add-white mb-1 mr-1" />
            {{ $t("general.createNew" ) + ' ' + $t("general.processingActivity" ).toLowerCase()}}
          </b-button>
          <b-button
            class="btn-nc nc-grid btn-dark-gray"
            tabindex="0"
            @click="generatePdf()"
            v-on:keyup.enter="generatePdf()"
            :disabled="isPdfButtonDisabled"
          >
            <i class="nc-pdf-white mb-1 mr-1" />
            {{ $t("file.downloadTheResultAsPDF") }}
          </b-button>
        </b-button-group>
        <filter-component
          :filter="filter"
          @update-filter="updateFilter"
          :setFilterFromStatusBoard="this.$route.params"
        />
      </b-col>
      <!-- search -->
      <b-col
        class="col-12 col-md-6 col-lg-8 col-xl-9"
        role="main"
      >
        <div class="container-fluid mt-4">
          <div class="d-inline">
            <b-row class="search-row">
              <search @update-search-text="updateSearchText" />
              <b-col
                md="3"
                sm="6"
              >
                <b-form-select
                  v-model="selectedSort"
                  :aria-label="$t('navigation.sortingoptions')"
                  :options="sortingOptions"
                />
              </b-col>
              <!-- grid & list view buttons -->
              <b-col>
                <b-button-group class="pl-4 float-right">
                  <b-button
                    class="btn-nc"
                    tabindex="0"
                    v-on:click="switchView()"
                    v-on:keyup.enter="switchView()"
                    :disabled="isGridView"
                    :aria-label="$t('general.gridView')"
                  >
                    <i v-bind:class="{'nc-tile-blue mb-1 mr-1': !isGridView, 'nc-tile-grey mb-1 mr-1': isGridView}" />
                  </b-button>
                  <b-button
                    class="btn-nc"
                    tabindex="0"
                    v-on:click="switchView()"
                    v-on:keyup.enter="switchView()"
                    :disabled="!isGridView"
                    :aria-label="$t('general.listView')"
                  >
                    <i v-bind:class="{'nc-list-blue mb-1 mr-1': isGridView, 'nc-list-grey mb-1 mr-1': !isGridView}" />
                  </b-button>
                </b-button-group>
              </b-col>
            </b-row>
            <!-- string number of search results shown -->
            <span
              class="d-inline-block text-dark"
              style="fontSize: .8rem"
            >
              {{ $t("general.displaying") + ' ' + activities.length + ' ' + $t("general.processingActivities").toLowerCase() }}
            </span>
          </div>
          <h2
            v-if="!activitiesLoading && (!activities || activities.length === 0) && !activitiesErrMsg"
            class="h2 text-muted text-center"
          >
            {{ $t("inputFeedbackMsg.searchNoMatchFound" )}}
          </h2>
          <b-alert
            v-if="!activitiesLoading && activitiesErrMsg"
            dismissible
            :show="this.activitiesErrMsg !== undefined && this.activitiesErrMsg !== null"
            variant="danger"
            @dismissed="this.activitiesErrMsg = null"
          >
            {{ activitiesErrMsg }}
          </b-alert>
          <div
            v-if="activitiesLoading"
            class="w-100 position-fixed m-0"
            style="zIndex: 1; top: 35%; left: 55%"
          >
            <b-spinner
              v-if="activitiesLoading"
              class="spinner "
              style="width: 5rem; height: 5rem;"
              variant="secondary"
            />
          </div>
          <div
            class="d-flex flex-wrap w-100"
            :style="activitiesLoading ? 'opacity: 0.3' : ''"
          >
            <div
              v-for="activity in sortedActivities"
              :key="activity.id"
              v-bind:class="isGridView ? [getActivityCardColor(activity.assignedUser.statusCode, true), 'm-2 shadow-sm bg-white'] : [getActivityCardColor(activity.assignedUser.statusCode, false), 'm-2 shadow-sm bg-white']"
              tabindex="0"
              @click="!activitiesLoading ? editActivity(activity.id) : null"
              v-on:keyup.enter="!activitiesLoading ? editActivity(activity.id) : null"
            >
              <div v-if="isGridView">
                <activity-card
                  :activity="activity"
                  :tenantActivityCardSettings="tenantActivityCardSettings"
                />

              </div>
              <div
                v-if="!isGridView"
                class=" d-flex flex-row align-content-start"
              >
                <div class="mr-auto">
                  <div
                    class="d-flex flex-row"
                    style="justify-content: space-between"
                  >
                    <span
                      class="text-wrap"
                      style="font-size:80%"
                    >{{ activity.aboutProcessingActivity.title }}</span>
                  </div>
                </div>
                <div class="col-lg-2">
                  <b-icon
                    :class="getActivityColor(activity.assignedUser && activity.assignedUser.statusCode)"
                    name="icontDot"
                  ></b-icon>
                  <span class="mr-2 status status-text">{{activity.assignedUser && getStatusText(activity.assignedUser.statusCode)}}</span>
                </div>
                <div style="flex: 0 0 3%; max-width: 3%;">
                  <i
                    v-if="activity.routineAndBestPractices.highRisk"
                    class="nc-flag"
                  />
                </div>
              </div>
            </div>
          </div>
          <activity-modal
            ref="processingActivityModal"
            :statusOptions="this.statusOptions"
            @update-processing-activities="updateProcessingActivities"
          >
          </activity-modal>
          <div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from 'axios'
import ActivityModal from '@/components/processingActivity/ActivityModal'
import Search from '@/components/Search'
import FilterComponent from '@/components/Filter'
import queryString from 'query-string'
import ActivityCard from '@/components/processingActivity/ActivityCard.vue'

export default {
  name: 'protocol',
  components: {
    ActivityModal,
    Search,
    FilterComponent,
    ActivityCard
  },
  data: function () {
    return {
      activities: [],
      searchText: '',
      filter: {},
      sortingOptions: [],
      // default settings which are used if nothing is returned from the API
      tenantActivityCardSettings: {
        id: null,
        assignedUserStatusAndDeadlineRow: true,
        purpose: false,
        areas: true,
        responsibleManager: false,
        categoriesOfDataSubjects: false,
        sharedResponsibility: true,
        disclosuresOfData: true,
        routinesAndApprovalComment: false
      },
      selectedSort: null,
      activitiesLoading: false,
      activitiesErrMsg: null,
      isGridView: true,
      isPdfButtonDisabled: false
    }
  },
  created: function () {
    this.getTentantActivityCardSettings()
    this.setSortingOptions()
    this.selectedSort = this.sortingOptions[0].value
  },
  mounted: function () {
    const id = this.$route.query.activity
    console.debug(id)
    if (id) {
      this.editActivity(id)
    }
  },
  computed: {
    sortedActivities: {
      get: function () {
        return this.activities.slice().sort(this.selectedSort)
      },
      set: function (selectedSort) {
        this.activities.slice().sort(selectedSort)
      }
    },
    statusOptions: function () {
      return [
        {
          id: 0,
          name: this.$t('activity.activityStatus.statusNotSet')
        },
        {
          id: 1,
          name: this.$t('activity.activityStatus.statusApproved')
        },
        {
          id: 2,
          name: this.$t('activity.activityStatus.statusNotApproved')
        },
        {
          id: 3,
          name: this.$t('activity.activityStatus.statusUnderProgress')
        },
        {
          id: 4,
          name: this.$t('activity.activityStatus.statusReadyForReview')
        },
        {
          id: 5,
          name: this.$t('activity.activityStatus.statusNeedsFollowUp')
        }
      ]
    }
  },
  methods: {
    createActivity: function () {
      this.$refs.processingActivityModal.toggle(null)
    },
    editActivity: function (activityId) {
      this.$refs.processingActivityModal.toggle(activityId)
    },
    getProcessingActivities: async function () {
      this.activitiesLoading = true
      axios
        .get('api/processingActivities', {
          params: {
            ...this.filter,
            textSearch: this.searchText
          },
          paramsSerializer: (params) => {
            return queryString.stringify(params, {
              arrayFormat: 'index',
              skipNull: true
            })
          }
        })
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.activities = response.data
            this.activities.forEach((activity) => {
              if (activity.disclosuresOfData) {
                activity.disclosureOfDataBusinessOrganisations =
                  this.getBusinessOrganisationDisclosure(
                    activity.disclosuresOfData
                  )
              }
              if (activity.processingAndStorageOfPersonalData) {
                activity.sharedResponsibilityBusinessOrganisations =
                  this.getBusinessOrganisationSharedResponsibility(
                    activity.processingAndStorageOfPersonalData
                  )
              }
            })
          }
        })
        .catch((e) => {
          console.error('CATCH: ', e)
          this.activitiesErrMsg = this.$t('errMsg.getGenericErrorMessage')
        })
        .finally(() => {
          this.activitiesLoading = false
        })
    },

    async getTentantActivityCardSettings () {
      axios
        .get('api/tenantActivityCardSettings/')
        .then((res) => {
          if (res.status === 200 && res.data) {
            this.tenantActivityCardSettings = res.data
          }
        })
        .catch(() => {
          this.errMsg.text = this.$t('errMsg.getGenericErrorMessage')
        })
    },
    async generatePdf () {
      this.isPdfButtonDisabled = true
      this.activitiesLoading = true
      await axios.put('/api/processingActivities/pdf/',
        {},
        {
          params: {
            ...this.filter,
            textSearch: this.searchText
          },
          paramsSerializer: (params) => {
            return queryString.stringify(params, {
              arrayFormat: 'index',
              skipNull: true
            })
          }
        }).then(async (response) => {
        const blobName = response.data
        await this.pollPdf(blobName)
      })
        .catch((error) => {
          console.error(error)
          this.activitiesErrMsg = this.$t(
            'errMsg.getGenericErrorMessage' + error.message
          )
          this.activitiesLoading = false
          this.isPdfButtonDisabled = false
        })
    },
    async pollPdf (blobId) {
      await axios.get('/api/processingActivities/pdf/', {
        params: { blobId },
        responseType: 'arraybuffer'
      }).then((response) => {
        const pollInterval = 5000
        if (response.status === 204) {
          setTimeout(() => this.pollPdf(blobId), pollInterval)
        } else if (response.status === 200) {
          const blob = new Blob([response.data], {
            type: response.headers['content-type']
          })
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          const filename =
            this.$t('file.activities') + '_' + this.$d(new Date()) + '.pdf'
          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()
          link.remove()
          window.URL.revokeObjectURL(url)
          this.activitiesLoading = false
          this.isPdfButtonDisabled = false
        }
      })
        .catch((error) => {
          console.error(error)
          this.activitiesErrMsg = this.$t(
            'errMsg.getGenericErrorMessage' + error.message
          )
          this.activitiesLoading = false
          this.isPdfButtonDisabled = false
        })
    },
    getBusinessOrganisationDisclosure (disclosures) {
      const businessFromSelectName = disclosures.map((disclosure) => {
        if (disclosure.businessOrganisation) {
          return disclosure.businessOrganisation.name
        }
        return null
      })
      const businessFromTextName = disclosures.map(
        (disclosure) => disclosure.customBusinessOrganisationName
      )
      return businessFromSelectName.concat(businessFromTextName).filter(Boolean)
    },
    getBusinessOrganisationSharedResponsibility (processingAndStorage) {
      if (processingAndStorage && processingAndStorage.sharedResponsibility) {
        if (
          processingAndStorage.sharedResponsibility.businessOrganisation &&
          processingAndStorage.sharedResponsibility
            .customBusinessOrganisationName
        ) {
          const sharedBusinessFromSelectName =
            processingAndStorage.sharedResponsibility.businessOrganisation.name
          const sharedBusinessFromTextName =
            processingAndStorage.sharedResponsibility
              .customBusinessOrganisationName
          return [sharedBusinessFromSelectName].concat([
            sharedBusinessFromTextName
          ])
        } else if (
          processingAndStorage.sharedResponsibility.businessOrganisation
        ) {
          return [
            processingAndStorage.sharedResponsibility.businessOrganisation.name
          ]
        } else if (
          processingAndStorage.sharedResponsibility
            .customBusinessOrganisationName
        ) {
          return [
            processingAndStorage.sharedResponsibility
              .customBusinessOrganisationName
          ]
        }
      } else return []
    },
    getAssignedUser (user) {
      if (user && user.assignedTo && user.assignedTo.name) {
        return user.assignedTo.name
      }
      return this.$t('general.shortNoAssignedUser')
    },
    getActivityColor (statusCode) {
      if (statusCode === 0 || !statusCode) {
        return 'mr-2 mt-1 dot-grey'
      }
      if (statusCode === 1) {
        return 'mr-2 mt-1 dot-green'
      }
      if (statusCode === 2) {
        return 'mr-2 mt-1 dot-red'
      }
      if ((statusCode === 3) | 4) {
        return 'mr-2 mt-1 dot-yellow'
      }
      return 'mr-2 mt-1 dot-grey'
    },
    getActivityCardColor (statusCode, isGridView) {
      if (statusCode === 0 || !statusCode) {
        return isGridView ? 'card-grey' : 'list-grey'
      }
      if (statusCode === 1) {
        return isGridView ? 'card-green' : 'list-green'
      }
      if (statusCode === 2) {
        return isGridView ? 'card-red' : 'list-red'
      }
      if ((statusCode === 3) | 4) {
        return isGridView ? 'card-yellow' : 'list-yellow'
      }
      return isGridView ? 'card-grey' : 'list-grey'
    },
    getStatusText (statusId) {
      return this.getStatusObject(statusId).name
    },
    getStatusObject (statusId) {
      if (!statusId) return this.statusOptions.find((obj) => obj.id === 0)
      if (statusId && (!statusId.id || !statusId.name)) {
        return this.statusOptions.find((obj) => obj.id === statusId)
      }
      return statusId
    },
    getDeadline (date) {
      if (date === '0001-01-01T00:00:00') {
        return this.$t('general.shortNoDeadline')
      }
      return date.replace('T00:00:00', '')
    },
    async updateProcessingActivities () {
      await this.getProcessingActivities()
    },
    async updateSearchText (searchText) {
      this.searchText = searchText
      await this.getProcessingActivities()
    },
    async updateFilter (filter) {
      this.filter = filter
      await this.getProcessingActivities()
    },
    switchView () {
      this.isGridView = !this.isGridView
    },
    setSortingOptions () {
      this.sortingOptions = [
        {
          value: (a, b) =>
            a.aboutProcessingActivity.title.localeCompare(
              b.aboutProcessingActivity.title, 'no'
            ),
          text: this.$t('sorting.byTitleAZ')
        },
        {
          value: (a, b) =>
            new Date(b.createdDateTime) - new Date(a.createdDateTime),
          text: this.$t('sorting.byCreateDateNewest')
        },
        {
          value: (a, b) =>
            new Date(a.createdDateTime) - new Date(b.createdDateTime),
          text: this.$t('sorting.byCreateDateOldest')
        },
        {
          value: (a, b) =>
            new Date(b.modifiedDateTime) - new Date(a.modifiedDateTime),
          text: this.$t('sorting.byModifiedDateNewest')
        },
        {
          value: (a, b) =>
            new Date(a.modifiedDateTime) - new Date(b.modifiedDateTime),
          text: this.$t('sorting.byModifiedDateOldest')
        },
        {
          value: (a, b) =>
            b.routineAndBestPractices.highRisk -
            a.routineAndBestPractices.highRisk,
          text: this.$t('sorting.byRiskHighLow')
        },
        {
          value: (a, b) =>
            a.routineAndBestPractices.highRisk -
            b.routineAndBestPractices.highRisk,
          text: this.$t('sorting.byRiskLowHigh')
        }
      ]
    }
  },
  watch: {
    '$i18n.locale': {
      handler () {
        this.setSortingOptions()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./../styles/_variables.scss";

.action-text {
  cursor: pointer;
}
.status-text {
  font-size: 80%;
  color: $nc-shade-dark;
}
</style>
