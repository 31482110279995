<template>
  <div>
    <b-row class="m-0">
      <b-col class="activity-box">
        <div class="activity-box-part">
          <h3>15. {{ $t('activity.disclosureOfDataTab.isTheDataProvidedToOthers') }}</h3>
          <yes-no-input
            :editMode="editMode"
            :value.sync="myIsDataDisclosed"
            :emptyDescription="this.$t('general.emptydata')"
            @update-yes-no="updateIsDataDisclosed" />
        </div>
      </b-col>
    </b-row>
    <div v-if="myIsDataDisclosed === true">
      <b-row v-show="editMode" class="m-0 mb-2 mt-2">
        <b-col class="activity-box-part">
          <b-button
            class="add-disclosure btn-nc"
            @click.prevent="addNewDisclosure">
            {{ $t('activity.disclosureOfDataTab.addBusinessOrganisation') }}
            <i v-show="editMode" class="ml-1 nc-add-blue" />
          </b-button>
        </b-col>
      </b-row>
      <b-row class="m-0">
        <b-col
          v-for="(columnItems, columnIndex) in [leftColumnDisclosureOfData, rightColumnDisclosureOfData]"
          :key="columnIndex"
          class="activity-box">
          <div v-for="(disclosure, disclosureIndex) in columnItems" :key="disclosure.id">
            <div class="activity-box-part">
              <loading-overlay :isLoading="isLoadingCodes" />
              <b-button-group>
                <b-button
                v-b-toggle="`collapse-` + disclosure.toggleId"
                class="mb-2 btn-nc moreLess"
                @click="disclosure.visible = !disclosure.visible">
                  {{ getToggleDisclosureText(disclosure) }}
                </b-button>
                <b-button v-if="editMode"
                  class="mb-2 btn-nc-red"
                  @click.prevent="removeDisclosure(disclosure)">
                  <i class="nc-delete-white" />
                </b-button>
              </b-button-group>
              <b-collapse
                :id="'collapse-' + disclosure.toggleId"
                class="mt-2"
                :visible="disclosure.visible">
                <disclosure
                  :activityId="activityId"
                  :businessOrganisationOptions="businessOrganisationOptions"
                  :disclosure="disclosure"
                  :key="disclosure.toggleId"
                  :editMode="editMode"
                  :index="columnIndex + '' + disclosureIndex"
                  :isDataDisclosed="myIsDataDisclosed"
                  :legalObligationsFromGdprOptions="legalObligationsFromGdprOptions"
                  :purposeOfDataSharingOptions="purposeOfDataSharingOptions"
                  :selectedCategoriesOfPersonalData="selectedCategoriesOfPersonalData" />
              </b-collapse>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import YesNoInput from '../common/YesNoInput'
import Disclosure from './disclosureOfData/Disclosure'
import LoadingOverlay from '../common/LoadingOverlay'

export default {
  name: 'disclosures-of-data',
  components: {
    YesNoInput,
    Disclosure,
    LoadingOverlay
  },
  props: {
    disclosuresOfData: {
      type: Array
    },
    isDataDisclosed: {
      type: Boolean
    },
    selectedCategoriesOfPersonalData: {
      type: Array,
      default: () => []
    },
    activityId: {
      type: String
    },
    editMode: {
      type: Boolean
    }
  },
  data: function () {
    return {
      myDisclosuresOfData: [],
      myIsDataDisclosed: null,
      selectedBusinessOrganisation: [],
      businessOrganisationOptions: [],
      legalObligationsFromGdprOptions: [],
      selectedPersonalData: [],
      selectedPurposeOfDataSharing: [],
      purposeOfDataSharingOptions: [
        {
          id: 1,
          name: this.$t('activity.disclosureOfDataTab.legalObligation')
        },
        {
          id: 2,
          name: this.$t('activity.disclosureOfDataTab.reasonForTransfer')
        },
        {
          id: 3,
          name: this.$t('activity.disclosureOfDataTab.consent')
        }
      ],
      isLoadingCodes: false
    }
  },
  computed: {
    console: () => console,
    leftColumnDisclosureOfData: function () {
      return this.myDisclosuresOfData.filter(function (value, index) {
        return index % 2 === 0
      })
    },
    rightColumnDisclosureOfData: function () {
      return this.myDisclosuresOfData.filter(function (value, index) {
        return index % 2 === 1
      })
    }
  },
  created: function () {
    this.getOptions()
    this.setUp()
  },
  methods: {
    setUp () {
      if (this.disclosuresOfData) {
        this.myDisclosuresOfData = [...this.disclosuresOfData].sort(this.sortDisclosuresAlphabetically)
      }
      if (this.isDataDisclosed !== undefined) {
        this.myIsDataDisclosed = this.isDataDisclosed
      }
      this.disclosuresOfData.forEach(disclosure => {
        disclosure.toggleId = this.guidGenerator()
      })
    },
    getOptions () {
      this.isLoadingCodes = true
      axios
        .all([
          axios.get('/api/businessOrganisations'),
          axios.get('/api/legalObligationsFromGdpr')
        ])
        .then(
          axios.spread((...responses) => {
            this.businessOrganisationOptions = responses[0].data
            this.legalObligationsFromGdprOptions = responses[1].data
          })
        )
        .catch(errors => {
          this.$emit(
            'error',
            this.$t('errMsg.getGenericErrorMessage') + ' ' + errors.message
          )
        })
        .finally(() => {
          this.isLoadingCodes = false
        })
    },
    getToggleDisclosureText (disclosure) {
      if (!disclosure.id) {
        return this.$t('activity.disclosureOfDataTab.newDisclosure')
      }
      if (disclosure.businessOrganisation) {
        return disclosure.businessOrganisation.name
      }
      if (disclosure.customBusinessOrganisationName) {
        return disclosure.customBusinessOrganisationName
      }
      return this.$t(
        'activity.disclosureOfDataTab.businessOrganisationIsntSelected'
      )
    },
    updateIsDataDisclosed (isDataDisclosed) {
      this.myIsDataDisclosed = isDataDisclosed
      if (!isDataDisclosed) {
        this.myDisclosuresOfData = []
      }
    },
    addNewDisclosure () {
      if (this.editMode) {
        this.myDisclosuresOfData.push({
          toggleId: this.guidGenerator(),
          visible: true
        })
      }
    },
    removeDisclosure (disclosure) {
      if (this.editMode) {
        this.myDisclosuresOfData.splice(this.myDisclosuresOfData.indexOf(disclosure), 1)
      }
    },
    guidGenerator () {
      const S4 = function () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
      }
      return (S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4())
    },
    sortDisclosuresAlphabetically (a, b) {
      const disclosureA = a.businessOrganisation?.name ?? a.customBusinessOrganisationName ?? this.$t('businessOrganisationIsntSelected')
      const disclosureB = b.businessOrganisation?.name ?? b.customBusinessOrganisationName ?? this.$t('businessOrganisationIsntSelected')

      return disclosureA.localeCompare(disclosureB, 'no')
    }
  },
  watch: {
    deep: true,
    immediate: true,
    myDisclosuresOfData: {
      handler () {
        this.$emit('update-disclosure-of-data', this.myDisclosuresOfData)
      },
      deep: true
    },
    myIsDataDisclosed: {
      handler () {
        this.$emit('update-is-data-disclosed', this.myIsDataDisclosed)
      }
    },
    disclosuresOfData: {
      handler () {
        this.myDisclosuresOfData = this.disclosuresOfData
      }
    }
  }
}
</script>

<style lang="scss">
@import './../../styles/_variables.scss';

.btn.add-disclosure {
    display: flex;
    align-items: center;
    padding: 0.75rem;

    i.nc-add-blue {
        display: flex;
        margin: 0;
    }
}
</style>
