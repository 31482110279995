<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="d-flex justify-content-center">
    <div class="d-flex flex-column shadow-sm bg-white pl-5 pr-5 pt-4 pb-4 m-5 w-25" role="main">
      <img class="m-auto" style="width: 15rem" src="/images/Team.jpg" alt="">
      <h2 class="text-center" style="color: #0974b3">{{ $t("navigation.vismaProtocol") }}</h2>
      <div v-if="errMsg" class="d-inline mt-1 mb-1">
        <b-alert variant="danger" dismissible class="w-100" :show="errMsg !== undefined && errMsg !== null" @dismissed="errMsg=null">{{ errMsg }}</b-alert>
      </div>
      <div class="d-inline mt-3 mb-2">
          <label for="text" style="color: #0974b3">{{ $t("login.email") }}:</label>
          <b-form-input @keypress.enter="login()" for="email" class="w-100" autofocus trim v-model="form.email" :aria-label="$t('login.emailInputPlaceholder')" :placeholder="$t('login.emailInputPlaceholder')"></b-form-input>
          <p class="text-danger mt-1 mb-1" v-if="!loading && invalidInputErrMsg">{{ invalidInputErrMsg }}</p>
      </div>
      <div class="d-inline mt-1 mb-1" v-cloak>
          <b-button @click.prevent="login()" type="button" class="w-100" style="border: 1px solid #0974b3; backgroundColor: #FFFFFF; color: #0974b3" :disabled="!isValidEmail && loading">
            <b-spinner small variant="secondary" class="spinner" v-if="loading"/>
            <img v-if="!loading" class="mr-1" style="width: 2rem" src="/logo/microsoft-logo-simple.png" alt="Microsoft logo">
              {{ $t("login.loginWithMicrosoft") }}
          </b-button>
      </div>
      <img class="m-auto" style="width: 10rem" src="/images/twoday.png" alt="twoday logo">
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      form: {
        email: ''
      },
      loading: false,
      errMsg: null,
      invalidInputErrMsg: null
    }
  },
  computed: {
    isValidEmail () {
      return this.$authService.searchForTenant(this.extractTenant())
    }
  },
  methods: {
    extractTenant () {
      if (this.form.email && this.form.email.length > 0) {
        let tenant = this.form.email
        if (tenant.includes('@')) {
          tenant = tenant.split('@')[1]
          if (tenant.includes('.')) {
            tenant = tenant.split('.')[0]
          }
        }
        return tenant
      }
    },
    login () {
      this.invalidInputErrMsg = null
      if (this.isValidEmail) {
        this.loading = true
        this.errMsg = null
        const tenant = this.extractTenant()
        this.$authService.login(tenant)
      } else {
        this.invalidInputErrMsg = this.$t('inputFeedbackMsg.loginEmailInputFeedbackError')
      }
      this.loading = false
    }
  }
}
</script>
