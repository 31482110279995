<template>
  <b-row class="m-0">
    <b-col class="activity-box">
      <div class="activity-box-part">
        <loading-overlay :isLoading="isLoadingCodes" />
        <heading-and-label
          :editMode="editMode"
          labelFor="processing-systems"
          :level="3"
        >
          9. {{ $t('activity.processingAndStorageTab.systemsWhereTheDataIsProcessed') }}
        </heading-and-label>
        <multi-select-input
          id="processing-systems"
          class="color-multiselect"
          :editMode="editMode"
          :emptyDescription="$t('general.emptydata')"
          :options="dataProccessedSystemsOptions"
          :placeholder="$t('activity.processingAndStorageTab.selectSystems')"
          :selectedOptions="selectedProcessedSystems"
          @update-select="updateProcessingSystems"
        />
        <div
          v-if="this.editMode"
          style="padding-top: 2px;"
          class="d-flex"
        >
          <b-button
            v-if="editMode"
            class="m-1 btn-nc"
            @click=selectAllProcessedSystems()
          >
            {{ $t('activity.chooseAll') }}
          </b-button>

          <b-button
            v-if="editMode"
            class="m-1 btn-nc"
            @click=selectClearProcessedSystems()
          >
            {{ $t('activity.clearSelection') }}
          </b-button>
        </div>
      </div>
      <div class="activity-box-part">
        <heading-and-label
          :editMode="editMode"
          labelFor="processing-where-stored"
          :level="3"
        >
          10. {{ $t('activity.processingAndStorageTab.systemsWhereTheDataIsStored') }}
        </heading-and-label>
        <multi-select-input
          id="processing-where-stored"
          class="color-multiselect"
          :editMode="editMode"
          :emptyDescription="$t('general.emptydata')"
          :options="dataStoredSystemsOptions"
          :placeholder="$t('activity.processingAndStorageTab.selectSystems')"
          :selectedOptions="selectedStoringSystems"
          @update-select="updateStoredSystems"
        />
        <div
          v-if="this.editMode"
          style="padding-top: 2px;"
          class="d-flex"
        >
        <b-button
            v-if="editMode"
            class="m-1 btn-nc"
            @click=selectAllStoredSystems()
          >
            {{ $t('activity.chooseAll') }}
          </b-button>

          <b-button
            v-if="editMode"
            class="m-1 btn-nc"
            @click=selectClearStoredSystems()
          >
            {{ $t('activity.clearSelection') }}
          </b-button>
        </div>
      </div>

      <div class="activity-box-part">
        <HelptextPopup
          target="Q11"
          :helptext="$t('helptext.q11')"
        />
        <h3 class="mt-1">11. {{ $t('activity.processingAndStorageTab.isThereADataProcessorInvolved') }}</h3>
        <yes-no-input
          id="processing-data-processor-involved"
          :editMode="editMode"
          :emptyDescription="$t('general.emptydata')"
          :value.sync="isDataProcessorInvolved"
          @update-yes-no="updateIsDataProcessorInvolved"
        />
        <div>
          <div
            v-if="isDataProcessorInvolved"
            class="activity-box-inner"
          >
          <b-button
            class="add-disclosure btn-nc"
            v-show="editMode"
            @click="addNewDataProcessor"
          >
              {{ $t('activity.processingAndStorageTab.addDataProcessor') }}
              <i v-show="editMode" class="ml-1 nc-add-blue" />
          </b-button>
            <div v-for="(dataProcessor) in this.myProcessingAndStorage.dataProcessors" :key="dataProcessor.toggleId">
              <DataProcessor
                :index="dataProcessor.toggleId"
                :editMode="editMode"
                :dataProcessor="dataProcessor"
                :activityId="activityId"
                :selectedCategoriesOfPersonalData="selectedCategoriesOfPersonalData"
                :businessOrganisationOptions="businessOrganisationOptions"
                :legalObligationsChapterFive="legalObligationsChapterFive"
                :removeDataProcessor="removeDataProcessor"
              />
            </div>
          </div>
        </div>
      </div>
    </b-col>
    <b-col class="activity-box">
      <loading-overlay :isLoading="isLoadingCodes" />
      <div class="activity-box-part">
        <HelptextPopup
          target="Q12"
          :helptext="$t('helptext.q12')"
        />
        <b-form-group class="m-0">
          <h3 class="mt-1"> 12. {{ $t('activity.processingAndStorageTab.isThereSharedResponsibility') }}</h3>
          <yes-no-input
            class="m-0"
            :editMode="editMode"
            :emptyDescription="$t('general.emptydata')"
            :value.sync="isResponsibilityShared"
            @update-yes-no="updateIsResponsibilityShared"
          />
        </b-form-group>
        <div v-if="isResponsibilityShared">
          <b-form-group class="activity-box-inner">
            <heading-and-label
              :editMode="editMode"
              labelFor="processing-business-of-shared"
              :level="4"
            >
              12.1 {{ $t('activity.processingAndStorageTab.businessOrganisationOfSharedResponsibility') }}
            </heading-and-label>
            <single-select-input
              id="processing-business-of-shared"
              class="color-multiselect"
              :editMode="editMode"
              :emptyDescription="$t('general.emptydata')"
              :options="businessOrganisationOptions"
              :placeholder="$t('activity.processingAndStorageTab.businessOrganisationPlaceholder')"
              :selectedOption="selectedSharedResponsibilityBusinessOrganisation"
              @update-select="updateSharedResponsibilityBusinessOrganisation"
            />

            <heading-and-label
              :editMode="editMode"
              labelFor="processing-shared-business-note"
              :level="4"
            >
              {{ $t('activity.processingAndStorageTab.businessOrganisationOther') }}
            </heading-and-label>
            <text-input
              id="processing-shared-business-note"
              class="mb-2"
              :editMode="editMode"
              :emptyDescription="$t('general.emptydata')"
              :placeholder="$t('activity.processingAndStorageTab.businessOrganisationOther')"
              :value="myProcessingAndStorage.sharedResponsibility.customBusinessOrganisationName"
              @update-text="updateSharedResponsibilityBusinessOrganisationName"
            />
          </b-form-group>

          <b-form-group class="activity-box-inner">
            <heading-and-label
              :editMode="editMode"
              labelFor="processing-shared-agreement"
              :level="4"
            >
              12.2 {{ $t('activity.processingAndStorageTab.agreement') }}
            </heading-and-label>
            <text-input
              id="processing-shared-agreement"
              class="mb-2"
              :editMode="editMode"
              :emptyDescription="$t('general.emptydata')"
              :placeholder="$t('activity.processingAndStorageTab.agreement')"
              :value="myProcessingAndStorage.sharedResponsibility.agreement"
              @update-text="updateSharedResponsibilityAgreement"
            />

            <heading-and-label
              :editMode="editMode"
              labelFor="processing-shared-agreement-file"
              :level="4"
            >
              {{ $t('file.file') }}
            </heading-and-label>
            <file-input
              id="processing-shared-agreement-file"
              :activityId="activityId"
              :attachment="myProcessingAndStorage.sharedResponsibility.agreementAttachmentInfo"
              class="mb-2"
              :editMode="editMode"
              :emptyDescription="$t('general.emptydata')"
              @remove-attachment="removeSharedResponsibilityAgreementAttachmentInfo"
              @update-attachment="updateSharedResponsibilityAgreementAttachmentInfo"
            />
          </b-form-group>
        </div>
      </div>
      <b-form-group class="activity-box-part">
        <HelptextPopup
          target="Q13"
          :helptext="$t('helptext.q13')"
        />
        <label class="mt-1">13. {{ $t('activity.processingAndStorageTab.isInternalRegisterWithPersonalDataUsed') }}</label>
        <yes-no-input
          :editMode="editMode"
          :emptyDescription="$t('general.emptydata')"
          :value.sync="isInternalRegisterIsUsed"
          @update-yes-no="updateIsInternalRegisterIsUsed"
        />
        <div
          v-if="isInternalRegisterIsUsed"
          class="mt-3"
        >
          <heading-and-label
            :editMode="editMode"
            labelFor="processing-personal-data-description"
            :level="4"
          >
            {{ $t('activity.processingAndStorageTab.description') }}
          </heading-and-label>
          <text-input
            id="processing-personal-data-description"
            :editMode="editMode"
            :emptyDescription="$t('general.emptydata')"
            :placeholder="$t('activity.processingAndStorageTab.description')"
            :value="myProcessingAndStorage.internalRegisterUsageDescription"
            @update-text="updateInternalRegisterUsageDescription"
          />
        </div>
      </b-form-group>
      <b-form-group class="activity-box-part">
        <HelptextPopup
          target="Q14"
          :helptext="$t('helptext.q14')"
        />
        <heading-and-label
          :editMode="editMode"
          labelFor="processing-time-limit"
          :level="4"
          class="mt-1"
        >
          14. {{ $t('activity.processingAndStorageTab.envisagedTimeLimitsForErasure') }}
        </heading-and-label>
        <text-input
          id="processing-time-limit"
          :editMode="editMode"
          :emptyDescription="$t('general.emptydata')"
          :placeholder="$t('activity.processingAndStorageTab.envisagedTimeLimitsForErasure')"
          :value="myProcessingAndStorage.envisagedTimeLimitsForErasure"
          @update-text="updateEnvisagedTimeLimitsForErasure"
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>
<script>
import axios from 'axios'
import HeadingAndLabel from '../common/HeadingAndLabel'
import FileInput from '../common/FileInput'
import LoadingOverlay from '../common/LoadingOverlay'
import MultiSelectInput from '../common/MultiSelectInput'
import SingleSelectInput from '../common/SingleSelectInput'
import TextInput from '../common/TextInput'
import YesNoInput from '../common/YesNoInput'
import HelptextPopup from '../processingActivity/HelptextPopup'
import DataProcessor from './dataProcessor/DataProcessor'

export default {
  name: 'processing-and-storage',
  components: {
    FileInput,
    HeadingAndLabel,
    LoadingOverlay,
    MultiSelectInput,
    SingleSelectInput,
    TextInput,
    YesNoInput,
    HelptextPopup,
    DataProcessor
  },
  created: async function () {
    await this.getOptions()
    this.setUp()
  },
  data: function () {
    return {
      myProcessingAndStorage: {},
      dataProccessedSystemsOptions: [],
      dataStoredSystemsOptions: [],
      selectedProcessedSystems: [],
      selectedStoringSystems: [],
      isAllStoredSystemsSelected: false,
      isClearStoredSystemsSelected: false,
      isDataProcessorInvolved: null,
      isAllProcessedSystemsSelected: false,
      isClearProcessedSystemsSelected: false,
      businessOrganisationOptions: [],
      legalObligationsChapterFive: [],
      isResponsibilityShared: null,
      selectedSharedResponsibilityBusinessOrganisation: null,
      isInternalRegisterIsUsed: null,
      isLoadingCodes: false
    }
  },
  props: {
    processingAndStorage: {
      type: Object,
      required: true
    },
    selectedCategoriesOfPersonalData: {
      type: Array,
      default: () => []
    },
    activityId: {
      type: String
    },
    editMode: {
      type: Boolean
    }
  },
  methods: {
    addNewDataProcessor () {
      this.myProcessingAndStorage.dataProcessors.push(
        {
          toggleId: this.guidGenerator(),
          businessOrganisationId: null,
          customBusinessOrganisationName: '',
          agreement: '',
          isDataProcessorOutsideEu: null,
          reasonForTransfer: null,
          reasonForTransferAttachmentInfo: null
        }
      )
      // Necessary for Vue to react to change in array
      this.myProcessingAndStorage = Object.assign({}, this.myProcessingAndStorage)
    },
    setUp () {
      this.myProcessingAndStorage = this.processingAndStorage
      this.selectedProcessedSystems = this.myProcessingAndStorage
        .processingSystems
        ? this.myProcessingAndStorage.processingSystems
        : []
      this.selectedStoringSystems = this.myProcessingAndStorage.storingSystems
        ? this.myProcessingAndStorage.storingSystems
        : []
      this.updateProcessingSystems(this.selectedProcessedSystems)
      this.updateStoredSystems(this.selectedStoringSystems)
      this.updateIsDataProcessorInvolved(
        this.myProcessingAndStorage.isDataProcessorInvolved
      )
      if (!this.myProcessingAndStorage.dataProcessors) {
        this.myProcessingAndStorage.dataProcessors = []
      } else {
        this.myProcessingAndStorage.dataProcessors.forEach(dataProcessor => {
          dataProcessor.toggleId = dataProcessor.id ?? dataProcessor.toggleId ?? this.guidGenerator()
        })
      }
      this.updateIsResponsibilityShared(
        this.myProcessingAndStorage.isResponsibilityShared
      )
      if (!this.myProcessingAndStorage.sharedResponsibility) {
        this.myProcessingAndStorage.sharedResponsibility = {}
      }
      this.updateSharedResponsibilityBusinessOrganisation(
        this.myProcessingAndStorage.sharedResponsibility.businessOrganisation
      )
      this.updateIsInternalRegisterIsUsed(
        this.myProcessingAndStorage.isInternalRegisterIsUsed
      )
    },
    async getOptions () {
      this.isLoadingCodes = true
      await axios
        .all([
          axios.get('/api/processingSystems'),
          axios.get('/api/storingSystems'),
          axios.get('/api/businessOrganisations'),
          axios.get('/api/legalObligationsFromGdpr')
        ])
        .then(
          axios.spread((...responses) => {
            this.dataProccessedSystemsOptions = responses[0].data
            this.dataStoredSystemsOptions = responses[1].data
            this.businessOrganisationOptions = responses[2].data
            this.legalObligationsChapterFive = responses[3].data
          })
        )
        .catch((errors) => {
          this.$emit(
            'error',
            this.$t('errMsg.getGenericErrorMessage') + ' ' + errors.message
          )
        })
        .finally(() => {
          this.isLoadingCodes = false
        })
    },
    updateProcessingSystems (processingSystems) {
      this.selectedProcessedSystems = processingSystems
      this.myProcessingAndStorage.processingSystemIds = processingSystems.map(
        (system) => system.id
      )
    },
    selectAllProcessedSystems () {
      this.updateProcessingSystems(this.dataProccessedSystemsOptions)
    },
    selectClearProcessedSystems () {
      this.updateProcessingSystems([])
    },
    updateStoredSystems (storingSystems) {
      this.selectedStoringSystems = storingSystems
      this.myProcessingAndStorage.storingSystemIds = storingSystems.map(
        (system) => system.id
      )
    },
    selectAllStoredSystems () {
      this.updateStoredSystems(this.dataStoredSystemsOptions)
    },
    selectClearStoredSystems () {
      this.updateStoredSystems([])
    },
    updateIsDataProcessorInvolved (isDataProcessorInvolved) {
      if (isDataProcessorInvolved == null) isDataProcessorInvolved = null
      this.isDataProcessorInvolved = isDataProcessorInvolved
      this.myProcessingAndStorage.isDataProcessorInvolved = isDataProcessorInvolved
    },
    removeDataProcessor (dataprocessor) {
      if (this.editMode) {
        this.myProcessingAndStorage.dataProcessors.splice(
          this.myProcessingAndStorage.dataProcessors.indexOf(dataprocessor), 1)

        this.myProcessingAndStorage = Object.assign({}, this.myProcessingAndStorage)
      }
    },
    updateIsResponsibilityShared (isResponsibilityShared) {
      if (isResponsibilityShared === undefined) isResponsibilityShared = null
      this.myProcessingAndStorage.isResponsibilityShared =
        isResponsibilityShared
      this.isResponsibilityShared = isResponsibilityShared
    },
    updateIsInternalRegisterIsUsed (isInternalRegisterIsUsed) {
      if (isInternalRegisterIsUsed === undefined) {
        isInternalRegisterIsUsed = null
      }
      this.myProcessingAndStorage.isInternalRegisterIsUsed =
        isInternalRegisterIsUsed
      this.isInternalRegisterIsUsed = isInternalRegisterIsUsed
    },
    updateSharedResponsibilityBusinessOrganisationName (
      customBusinessOrganisationName
    ) {
      this.myProcessingAndStorage.sharedResponsibility.customBusinessOrganisationName =
        customBusinessOrganisationName
    },
    updateSharedResponsibilityBusinessOrganisation (businessOrganisation) {
      if (businessOrganisation) {
        this.selectedSharedResponsibilityBusinessOrganisation =
          businessOrganisation
        this.myProcessingAndStorage.sharedResponsibility.businessOrganisationId =
          businessOrganisation.id
      } else {
        this.selectedSharedResponsibilityBusinessOrganisation = null
        this.myProcessingAndStorage.sharedResponsibility.businessOrganisationId =
          null
      }
    },
    updateSharedResponsibilityAgreement (agreement) {
      this.myProcessingAndStorage.sharedResponsibility.agreement = agreement
    },
    updateSharedResponsibilityAgreementAttachmentInfo (agreementAttachment) {
      this.myProcessingAndStorage.sharedResponsibility.agreementAttachment =
        agreementAttachment
    },
    removeSharedResponsibilityAgreementAttachmentInfo (attachmentId) {
      if (
        this.myProcessingAndStorage.sharedResponsibility.agreementAttachmentInfo
          .id === attachmentId
      ) {
        this.myProcessingAndStorage.sharedResponsibility.agreementAttachment =
          null
        this.myProcessingAndStorage.sharedResponsibility.agreementAttachmentInfo =
          null
      }
    },
    updateInternalRegisterUsageDescription (internalRegisterUsageDescription) {
      this.myProcessingAndStorage.internalRegisterUsageDescription =
        internalRegisterUsageDescription
    },
    updateEnvisagedTimeLimitsForErasure (envisagedTimeLimitsForErasure) {
      this.myProcessingAndStorage.envisagedTimeLimitsForErasure =
        envisagedTimeLimitsForErasure
    },
    guidGenerator () {
      const S4 = function () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
      }
      return (S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4())
    }
  },
  watch: {
    myProcessingAndStorage: {
      handler () {
        this.$emit('update-processing-and-storage', this.myProcessingAndStorage)
      },
      deep: true
    },
    processingAndStorage: {
      handler () {
        this.setUp()
      }
    }
  }
}
</script>
<style scoped lang="scss">
@import "../../styles/_variables.scss";
.nc-background {
  background-color: $background-color-2;
}
</style>
