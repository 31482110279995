<template>
  <div v-if="editMode">
    <b-form-input
      :id="id"
      v-model="textInputValue"
      class="mb-2"
      :placeholder="placeholder"
      maxLength="750"
      @change="updateTextInput()"></b-form-input>
  </div>
  <div v-else>
    <p v-if="!editMode && textInputValue" class="font-weight-bold m-0">{{ textInputValue }}</p>
    <p v-else-if="!editMode && !textInputValue" class="empty-value m-0">{{emptyDescription}}</p>
  </div>
</template>

<script>
export default {
  name: 'text-input',
  props: {
    id: {
      type: String
    },
    value: {
      type: String
    },
    placeholder: {
      type: String
    },
    editMode: {
      type: Boolean
    },
    emptyDescription: {
      type: String
    },
    maxLength: {
      type: String
    }
  },
  data () {
    return {
      textInputValue: this.value
    }
  },
  watch: {
    value (newValue) {
      this.textInputValue = newValue
    }
  },
  methods: {
    updateTextInput () {
      this.textInputValue = this.textInputValue.trim()
      this.$emit('update-text', this.textInputValue)
    }
  }
}
</script>
